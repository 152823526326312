
import React, { useState } from 'react';

function Qualifiers() {
  const userImages = [
    '/assets/qualifying/Official Hackathon Participants_page-0002.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0003.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0004.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0005.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0006.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0007.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0008.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0009.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0010.jpg',
    '/assets/qualifying/Official Hackathon Participants (2)_page-0011.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0012.jpg',
    '/assets/qualifying/Official Hackathon Participants_page-0013.jpg',
    '/assets/qualifying/Official Hackathon Participants (2)_page-0014.jpg',
  ];

  const portfolioUrls = [
    'https://www.annastacia.dev/',
    'https://3d-portfolio-dusky-chi.vercel.app/',
    'https://github.com/lokified',
    'https://personal-portfolio-coral-gamma.vercel.app/',
    'https://crepant.com',
    'https://nalugala-vc.github.io/Updated-Portfolio/',
    'https://everythingthairu.xyz',
    'https://elizabethodhiambo.me/',
    'https://brianmugo.framer.website/',
    'https://www.notion.so/debbienyasetia/Debbie-Nyasetia-3c14dfce7f6d411b96fb100e3b5c5ff1',
    'https://abelbiwott.com/',
    'https://newtvn.github.io/react-portfolio/',
  ];

  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  return (
    <div className="bg-gradient-to-r from-black via-[#0b0b0b] to-[#1a1a1a] p-10">

 {/* Container for the main image and the overlay */}
<div className="relative mb-10">
  {/* Full-width main image */}
  <img
    src="/assets/qualifying/design.png"
    alt="Featured Hackathon Image"
    className="w-full h-[400px] object-cover rounded-lg shadow-2xl ring-4 ring-[#EA516F] hover:ring-[#58EAED] transition-all duration-500"
  />

  {/* Overlay text */}
  <div className="absolute bottom-8 left-8 text-white font-bold text-sm md:text-sm tracking-wide shadow-lg">
    Zuri Health 2024 Edition Hackathon
  </div>

  {/* Overlay image with futuristic style */}
  <div className="absolute top-4 left-4 transform hover:scale-110 transition-all duration-500">
    <img
      src="/assets/qualifying/zuri.png" // Replace with your overlay image path
      alt="Overlay Image"
      className="w-32 h-32 object-cover rounded-full shadow-2xl ring-2 ring-[#58EAED] hover:ring-[#EA516F] transition-all duration-300"
    />
  </div>
</div>


{/* Custom layout for candidate images */}
<div className="flex justify-center">
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
    {userImages.map((image, index) => (
      <div
        key={index}
        className="relative transform transition-all duration-500 hover:scale-110 hover:rotate-3 hover:shadow-2xl hover:opacity-90"
        onClick={() => handleImageClick(index)}
      >
        <a
          href={portfolioUrls[index]}
          target="_blank"
          rel="noopener noreferrer"
          className="group"
        >
          {/* Main image */}
          <img
            src={image}
            alt={`User ${index + 1}`}
            className="w-full h-72 object-cover rounded-lg shadow-lg group-hover:scale-105 group-hover:shadow-xl transition-all duration-300 sm:w-60 sm:h-80 md:w-48 md:h-64"
          />

          {/* Overlay image positioned at the top right */}
          <img
            src="/assets/qualifying/zuri.png"
            alt="Overlay Icon"
            className="absolute top-2 right-2 w-8 h-8 object-contain rounded-full shadow-md"
          />
        </a>
      </div>
    ))}
  </div>
</div>



      {/* Modal with smooth animation for expanded image */}
      {activeImageIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20 transition-all duration-500 ease-in-out transform scale-100 opacity-100">
          <div className="bg-gradient-to-r from-[#EA516F] via-[#58EAED] to-[#1c1c1c] p-6 rounded-lg shadow-2xl relative w-[60vw] max-w-[600px] transform transition-transform duration-500 ease-in-out">
            <button
              onClick={() => setActiveImageIndex(null)}
              className="absolute top-4 right-4 text-3xl text-white hover:text-red-600 transition-all duration-300"
            >
              &times;
            </button>
            <img
              src={userImages[activeImageIndex]}
              alt={`Expanded Image ${activeImageIndex + 1}`}
              className="w-full h-auto object-contain max-h-[70vh] rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}

      {/* Footer with futuristic design */}
      <footer className="bg-gradient-to-r from-[#58EAED] to-[#EA516F] text-white text-center py-6 mt-10 relative">
        <div className="absolute inset-0 bg-gradient-to-r from-[#121212] to-[#292929] opacity-30"></div>
        <p className="text-sm text-shadow-lg transform-gpu text-transparent bg-clip-text bg-gradient-to-r from-[#EA516F] via-[#58EAED] to-[#54F2F6]">
          Hackathon 2024 powered by Zuri Health
        </p>
      </footer>
    </div>
  );
}

export default Qualifiers;