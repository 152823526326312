// src/App.js
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import React from 'react';
import Survey from './components/Survey';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClientRegistrationForm from './components/ClientRegistrationForm';
import Questionnaire from './components/Questionnare';
import Qualifiers from './components/Qualifiers';

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer position='top-center' />
        <Routes>
          <Route exact path='/survey' element={<Survey />} />
          <Route exact path='/registration' element={<ClientRegistrationForm />} />
          <Route exact path='/zuri_questionarre' element={<Questionnaire/>}/>
          <Route exact path='/hackathon_qualifiers' element={<Qualifiers />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
