import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Questionnaire = () => {
  const [partnerType, setPartnerType] = useState('');
  const [formData, setFormData] = useState({
    politenessAndProfessionalism: {
      questions: Array(3).fill(''),
      openEndedResponses: [''],
    },
    communicationEffectiveness: {
      questions: Array(4).fill(''),
      openEndedResponses: Array(5).fill(''),
    },
    additionalComments: [''],
  });

  const apiUrl = 'https://medicalcampapi.zuri.health/api/Q&A/submit';

  const handleInputChange = (section, type, index, value) => {
    setFormData((prevData) => {
      const updatedSection = {
        ...prevData[section],
        [type]: [...(prevData[section][type] || [])],
      };
      updatedSection[type][index] = value;
      return { ...prevData, [section]: updatedSection };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiUrl, { partnerType, ...formData });
      toast.success('Questionnaire submitted successfully!');
      console.log(response.data);
      
      // Clear form fields after successful submission
      setPartnerType('');
      setFormData({
        politenessAndProfessionalism: {
          questions: Array(3).fill(''),
          openEndedResponses: [''],
        },
        communicationEffectiveness: {
          questions: Array(4).fill(''),
          openEndedResponses: Array(5).fill(''),
        },
        additionalComments: [''],
      });
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Error submitting the questionnaire. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8 flex flex-col items-center">
      <h1 className="text-4xl font-bold mb-6 text-[#EA516F]">Partner Feedback Questionnaire</h1>
      <p className="text-center text-lg mb-10 text-gray-700 max-w-2xl">
        Thank you for providing feedback to help us improve! Please complete this questionnaire.
      </p>

      <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl border-t-4 border-[#56F2F6]">
        {/* Partner Type Selection */}
        <div className="mb-6">
          <label className="block text-lg font-semibold text-gray-800">Select Partner Type:</label>
          <select
            value={partnerType}
            onChange={(e) => setPartnerType(e.target.value)}
            className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F] focus:ring-2 focus:ring-[#56F2F6]"
            required
          >
            <option value="">Select Partner Type</option>
            <option value="Pharmacy">Pharmacy</option>
            <option value="Lab">Lab</option>
          </select>
        </div>

        {/* Politeness and Professionalism Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3 text-[#EA516F]">Politeness and Professionalism</h2>

          <div className="space-y-4">
            <div>
              <label className="block font-medium text-gray-700 mb-1">
                1. How would you rate the courtesy and respect shown by our customer care agents?
              </label>
              <select
                onChange={(e) => handleInputChange('politenessAndProfessionalism', 'questions', 0, e.target.value)}
                className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
                required
              >
                <option value="">Rate: 1 (Poor) - 5 (Excellent)</option>
                {[1, 2, 3, 4, 5].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block font-medium text-gray-700 mb-1">
                2. How often do our agents exhibit professionalism when seeking information?
              </label>
              <select
                onChange={(e) => handleInputChange('politenessAndProfessionalism', 'questions', 1, e.target.value)}
                className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
                required
              >
                <option value="">Rate: 1 (Rarely) - 5 (Always)</option>
                {[1, 2, 3, 4, 5].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block font-medium text-gray-700 mb-1">3. Have you experienced any instances where our agents were impolite or unprofessional? If yes, please describe.</label>
              <textarea
                onChange={(e) => handleInputChange('politenessAndProfessionalism', 'openEndedResponses', 0, e.target.value)}
                className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
                placeholder="Describe if any"
              ></textarea>
            </div>
          </div>
        </section>

        {/* Communication Effectiveness Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3 text-[#EA516F]">Communication Effectiveness</h2>

          <div className="space-y-4">
            {["How clear are our agents' requests for information?", "Do our agents provide necessary patient information?", "Do our agents respond promptly?", "How would you rate the agents' listening skills?"].map((question, index) => (
              <div key={index}>
                <label className="block font-medium text-gray-700 mb-1">{index + 1}. {question}</label>
                <select
                  onChange={(e) => handleInputChange('communicationEffectiveness', 'questions', index, e.target.value)}
                  className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
                  required
                >
                  <option value="">Rate: 1 (Poor) - 5 (Excellent)</option>
                  {[1, 2, 3, 4, 5].map((num) => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
              </div>
            ))}

            <div>
              <label className="block font-medium text-gray-700 mb-1">5. Do you have any suggestions for improving communication?</label>
              <textarea
                onChange={(e) => handleInputChange('communicationEffectiveness', 'openEndedResponses', 4, e.target.value)}
                className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
                placeholder="Your suggestions"
              ></textarea>
            </div>
          </div>
        </section>

        {/* Additional Comments Section */}
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3 text-[#EA516F]">Additional Comments</h2>
          <textarea
            onChange={(e) => handleInputChange('additionalComments', '0', 0, e.target.value)}
            className="w-full px-3 py-2 border rounded-md outline-none focus:border-[#EA516F]"
            placeholder="Any additional feedback or suggestions"
          ></textarea>
        </section>

        <button
          type="submit"
          className="w-full bg-[#EA516F] hover:bg-[#56F2F6] text-white font-semibold py-3 rounded-md transition-colors"
        >
          Submit Questionnaire
        </button>
      </form>

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </div>
  );
};

export default Questionnaire;
