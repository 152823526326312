// src/components/ClientRegistrationForm.js
import CommunityWorkerDropdown from './CommunityWorkerDropdown';
import Axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState } from 'react';

const baseUrl = 'https://hdaatb6m3i.us-west-2.awsapprunner.com/api/chw';

const ClientRegistrationForm = () => {
  const [selectedWorker, setSelectedWorker] = useState('');
  const [clientData, setClientData] = useState({
    name: '',
    age: '',
    phoneNumber: '',
    residence: '',
    referralFacility: '',
  });
  const [loading, setLoading] = useState(false);

  const handleWorkerSelection = (e) => {
    setSelectedWorker(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const cleanedPhoneNumber = clientData.phoneNumber.replace(/\s/g, '');

    try {
      const response = await Axios.post(`${baseUrl}/register`, {
        selected_worker: selectedWorker,
        referral_facility: clientData.referralFacility,
        name: clientData.name,
        age: clientData.age,
        location: clientData.residence,
        phone_number: cleanedPhoneNumber,
      });
  
      console.log('Server Response:', response.data.message);
      toast.success('User registered successfully');
      
      // Clear form fields after successful submission
      setClientData({
        name: '',
        age: '',
        phoneNumber: '',
        residence: '',
        referralFacility: '',
      });
  
    } catch (error) {
        toast.error(error?.response?.data?.errors[0]?.msg || error?.response?.data?.message || 'Error submitting form');

      console.error(error?.response?.data?.errors[0]?.msg);
      
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gradient-to-r from-[#EA526F] to-[#5BDADD] bg-opacity-80">
      <form onSubmit={handleSubmit} className="max-w-md p-6 bg-white rounded-md shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-4">Client Registration Form</h2>

        <CommunityWorkerDropdown
          selectedWorker={selectedWorker}
          onSelectWorker={handleWorkerSelection}
          workers={[
            { id: 1, name: 'Evarline Ayuma' },
            { id: 2, name: 'Everline Adhiambo' },
            { id: 3, name: 'Gladwel Wambui' },
            { id: 4, name: 'Christine Anyungu' },
            { id: 5, name: 'Victoria Mutua' },
            { id: 6, name: 'Mark Omariba' },
            { id: 7, name: 'Gladys Nyambura' },
            { id: 8, name: 'Millicent Nandonya' },
            { id: 9, name: 'Susan Kemuma' },
            { id: 10, name: 'Millicent Achieng' },
            // Add more workers as needed
          ]}
        />


        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name of the client
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={clientData.name}
            onChange={handleInputChange}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="age" className="block text-sm font-medium text-gray-700">
            Age
          </label>
          <input
            type="number"
            id="age"
            name="age"
            value={clientData.age}
            onChange={handleInputChange}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={clientData.phoneNumber}
            onChange={handleInputChange}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="residence" className="block text-sm font-medium text-gray-700">
            Residence
          </label>
          <input
            type="text"
            id="residence"
            name="residence"
            value={clientData.residence}
            onChange={handleInputChange}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
          />
        </div>

        {/* <div className="mb-4">
          <label htmlFor="referralFacility" className="block text-sm font-medium text-gray-700">
            Referral Facility
          </label>
          <input
            type="text"
            id="referralFacility"
            name="referralFacility"
            value={clientData.referralFacility}
            onChange={handleInputChange}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
          />
        </div> */}

        <button
          type="submit"
          className="bg-[#5BDADD] text-white p-2 rounded-md hover:bg-[#EA526F] focus:outline-none focus:ring focus:border-indigo-300"
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {/* Footer */}
        <div className="mt-8 text-gray-600 text-sm text-center">
          Powered by Zuri Health 2024. All rights reserved.
        </div>
      </form>
    </div>
  );
};

export default ClientRegistrationForm;
