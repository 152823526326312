
import Axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState } from 'react';

const baseUrl = 'https://hdaatb6m3i.us-west-2.awsapprunner.com/api/survey';
// const baseUrl ='http://localhost:8000/api/survey';


function Survey() {

    const [formData, setFormData] = useState({
        phone_number: '',
        age: '',
        location: '',
        marital_status: '',
        occupation: '',
        platform_registered: '',
        chat_interaction: '',
        service_satisfaction: '',
        contraceptive_information: '',
        clinic_referall: '',
        fitted_since_conversation: '',
        location_client_got_fitted: '',
        contraceptive_type: '',
        recieve_information: '',
        additional_contraceptive_information: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await Axios.post(`${baseUrl}/submit_survey`, formData);
          if (response.status === 200) {
            console.log('Server Response:', response.data.message);
            toast.success('ThankYou For Successfully Completing the Zuri Health Survey');
            setFormData({
              phone_number: '',
              age: '',
              location: '',
              marital_status: '',
              occupation: '',
              platform_registered: '',
              chat_interaction: '',
              service_satisfaction: '',
              contraceptive_information: '',
              clinic_referall: '',
              fitted_since_conversation: '',
              location_client_got_fitted: '',
              contraceptive_type: '',
              recieve_information: '',
              additional_contraceptive_information: ''
            });
          }
        } 
        catch (error) {
            toast.error(error?.response?.data?.errors[0]?.msg || error?.response?.data?.message || 'Failed to submit survey. Please Answer all Questions.');
    
          console.error(error?.response?.data?.errors[0]?.msg);
        } 
      };
  return (
    <div className="min-h-screen  bg-gray-100 flex flex-col ">
      {/* Navbar */}
      <nav className="bg-white shadow bg-gradient-to-r from-[#FFFFFF] to-[#5BDADD] bg-opacity-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-start ">
            <img className="h-12 mr-4" src="/assets/logo1.png" alt="Logo 1" />
            <img className="h-12" src="/assets/logo2.png" alt="Logo 2" />
          </div>
        </div>
      </nav>

      {/* Body with the questions */}
      <div className="flex-grow text-center">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-6 ">WOMEN REACHED BY COMMUNITY HEALTH WORKERS SURVEY</h1>

{/* Section 1: Demographics */}
          <section className="mb-8">
            <h2 className="text-xl font-bold mb-4 ">Section 1: Demographics</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div>
                <label htmlFor="phone_number">1.1. Phone number:</label>
                <input type="text" id="phone_number" name="phone_number" className="border border-gray-300 rounded-md p-2 w-full" value={formData.phone_number} onChange={handleChange} />
              </div>
              <div>
                <label htmlFor="age">1.2. Age:</label>
                <select id="age" name="age" className="border border-gray-300 rounded-md p-2 w-full" value={formData.age} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Under 18">Under 18</option>
                  <option value="18-25">18-25</option>
                  <option value="26-35">26-35</option>
                  <option value="36-45">36-45</option>
                  <option value="Over 45">Over 45</option>
                </select>
              </div>
              <div>
                <label htmlFor="location">1.3. Location:</label>
                <input type="text" id="location" name="location" className="border border-gray-300 rounded-md p-2 w-full" value={formData.location} onChange={handleChange} />
              </div>
              <div>
                <label htmlFor="marital_status">1.4. Marital Status:</label>
                <select id="marital_status" name="marital_status" className="border border-gray-300 rounded-md p-2 w-full" value={formData.marital_status} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                  <option value="In a relationship">In a relationship</option>
                </select>
              </div>
              <div>
                <label htmlFor="occupation">1.5. Occupation:</label>
                <select id="occupation" name="occupation" className="border border-gray-300 rounded-md p-2 w-full" value={formData.occupation} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Employed">Employed</option>
                  <option value="Non-Employed">Non-Employed</option>
                  <option value="Student">Student</option>
                </select>
              </div>
            </div>
          </section>

          {/* Section 2: CHP Experience */}
          <section className="mb-8">
            <h2 className="text-xl font-bold mb-4">Section 2: CHP Experience</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div>
                <label htmlFor="platform_registered">2.1. Which platform were you registered for NikoNaChoice?</label>
                <select id="platform_registered" name="platform_registered" className="border border-gray-300 rounded-md p-2 w-full" value={formData.platform_registered} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="SMS">SMS</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Other">Other (please specify)</option>
                </select>
              </div>
              <div>
                <label htmlFor="chat_interaction">2.2. Have you been able to chat with a NikoNaChoice on the BOT or SMS?</label>
                <select id="chat_interaction" name="chat_interaction" className="border border-gray-300 rounded-md p-2 w-full" value={formData.chat_interaction} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="service_satisfaction">2.3. Were you satisfied with the services provided by the CHP?</label>
                <select id="service_satisfaction" name="service_satisfaction" className="border border-gray-300 rounded-md p-2 w-full" value={formData.service_satisfaction} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Very satisfied">Very satisfied</option>
                  <option value="Somewhat satisfied">Somewhat satisfied</option>
                  <option value="Neutral">Neutral</option>
                  <option value="Somewhat dissatisfied">Somewhat dissatisfied</option>
                  <option value="Very dissatisfied">Very dissatisfied</option>
                </select>
              </div>
            </div>
          </section>

          {/* Section 3: Contraceptive Services */}
          <section className="mb-8">
            <h2 className="text-xl font-bold mb-4">Section 3: Contraceptive Services</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div>
                <label htmlFor="contraceptive_information">3.1. Did you receive information about modern contraceptives from the CHP?</label>
                <select id="contraceptive_information" name="contraceptive_information" className="border border-gray-300 rounded-md p-2 w-full" value={formData.contraceptive_information} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="clinic_referall">3.2. Were you referred to a clinic for modern contraceptives fittings?</label>
                <select id="clinic_referall" name="clinic_referall" className="border border-gray-300 rounded-md p-2 w-full" value={formData.clinic_referall} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="fitted_since_conversation">3.3. Have you gotten fitted with contraceptives since the conversation with the CHP?</label>
                <select id="fitted_since_conversation" name="fitted_since_conversation" className="border border-gray-300 rounded-md p-2 w-full" value={formData.fitted_since_conversation} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label htmlFor="location_client_got_fitted">3.4. Where did you get the Contraceptives fitted from? (Name of the clinic)</label>
                <input type="text" id="location_client_got_fitted" name="location_client_got_fitted" className="border border-gray-300 rounded-md p-2 w-full" value={formData.location_client_got_fitted} onChange={handleChange} />
              </div>
              <div>
                <label htmlFor="contraceptive_type">3.5. What type of Contraceptives did you get fitted with (name of contraceptives)?</label>
                <input type="text" id="contraceptive_type" name="contraceptive_type" className="border border-gray-300 rounded-md p-2 w-full" value={formData.contraceptive_type} onChange={handleChange} />
              </div>
            </div>
          </section>

          {/* Section 4: Future Preferences */}
          <section className="mb-8">
            <h2 className="text-xl font-bold mb-4">Section 4: Future Preferences</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div>
                <label htmlFor="recieve_information">4.1. Would you be interested in receiving information about different contraceptive methods and services through SMS or WhatsApp?</label>
                <select id="recieve_information" name="recieve_information" className="border border-gray-300 rounded-md p-2 w-full" value={formData.recieve_information} onChange={handleChange}>
                <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div>
              <label htmlFor="additional_contraceptive_information">4.2. What additional contraceptive information or services would you like to receive through the initiative?</label>
                <input type="text" id="additional_contraceptive_information" name="additional_contraceptive_information" className="border border-gray-300 rounded-md p-2 w-full" value={formData.additional_contraceptive_information} onChange={handleChange} />
              </div>

            </div>
            <button onClick={handleSubmit} className="bg-[#EA526F] hover:bg-[#5BDADD] text-white font-bold py-2 px-4 rounded mt-4 mx-auto">Submit Survey</button>
          </section>

          <p className="text-sm mb-4 italic  ">Thank you for participating in this survey. Your input is invaluable in our efforts to improve access to modern contraceptives in Kenya.</p>
          <p className="text-xs mb-4 italic">This questionnaire is designed to collect feedback from women who have attended health camps as part of our initiative. It focuses on their experiences and contraceptive needs, helping to tailor the project's services accordingly.</p>
        </div>
      </div>

      {/* Footer */}
      
      <footer className="bg-gray-800 text-white text-center items-center py-4 bg-gradient-to-r from-[#EA526F] to-[#5BDADD] bg-opacity-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p>ZURI HEALTH HQ, EMILIA APARTMENTS, GATUNDU ROAD, KILELESHWA</p>
          <p>P.O. BOX 103785-00101</p>
          <a href="http://www.zuri.health" className="underline">WWW.ZURI.HEALTH</a>
          <p>+254 742 820051</p>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-center"> 
    <img className="h-12 mr-4" src="/assets/logo1.png" alt="Logo 1" />
    <img className="h-12" src="/assets/logo2.png" alt="Logo 2" />
  </div>
        </div>
        <div className="mt-8 text-gray-600 text-sm text-center">
          Powered by Zuri Health 2024. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Survey;
