// src/components/CommunityWorkerDropdown.js
import React from 'react';

const CommunityWorkerDropdown = ({ selectedWorker, onSelectWorker, workers }) => {
  return (
    <div className="mb-4 ">
      <label htmlFor="communityWorker" className="block text-sm font-medium text-gray-700">
        Community Health Worker
      </label>
      <select
        id="communityWorker"
        name="communityWorker"
        value={selectedWorker}
        onChange={onSelectWorker}
        disabled={selectedWorker !== ''}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-[#EA526F]"
      >
        <option value="" disabled>
          Select Community Health Worker
        </option>
        {workers.map((worker) => (
          <option key={worker.id} value={worker.name}>
            {worker.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CommunityWorkerDropdown;
